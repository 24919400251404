import { Alert, Snackbar as _Snackbar } from '@mui/material';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbar } from '../../redux/root_action';

interface Props {}

const Snackbar: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: any) => state.Common.snackbar) || {};

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      toggleSnackbar({
        isOpen: false,
        message: '',
        severity: 'success',
      })
    );
  };

  return (
    <_Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackbar.isOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        variant='filled'
        severity={snackbar.severity}
        sx={{
          '& .MuiAlert-icon': {
            pt: snackbar.type ? '11px' : '',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {snackbar.message}
        </div>
      </Alert>
    </_Snackbar>
  );
};
export default Snackbar;
