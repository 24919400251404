import { getUserIdToken } from './auth';
export const callApi = async (paylod: any) => {
  const { url, options } = paylod;
  const accessToken = await getUserIdToken();
  // console.log(accessToken)
  options.headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  try {
    if (options?.body && typeof options.body !== 'string') {
      const body = JSON.stringify(options.body);
      options.body = body;
    }
    const response = await fetch(url, options);
    const body = await response.json();
    if (response.ok) {
      return body;
    } else {
      throw body;
    }
  } catch (error) {
    throw error;
  }
};
