import { useSelector } from 'react-redux';

export const useGetRole = () => {
  const auth = useSelector((store: any) => store.Common.auth) || {};
  return auth?.role || null;
};
export const useGetCompanyId = () => {
  const auth = useSelector((store: any) => store.Common.auth) || {};
  return auth?.companyId;
};
export const useGetClientId = () => {
  const auth = useSelector((store: any) => store.Common.auth) || {};
  return auth?.clientId;
};
