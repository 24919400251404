import React from 'react';
import { Route } from 'react-router';
import { PrivateRoute } from './PrivateRoute';
import NoAccess from '../pages/noAccess/NoAccess';
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const AdminLicenseList = React.lazy(() => import('../pages/admin/LicenseList'));
const FacilitatorSessionList = React.lazy(
  () => import('../pages/facilitator/SessionList')
);
const AnalyticsDashboard = React.lazy(
  () => import('../4plants-analytics/pages/dashboard/Dashboard')
);

export const routes = [
  {
    path:'/',
    element:<PrivateRoute/>,
    children:[
      {
        path: '/logout',
        component: <Logout/>,
      },
      {
        path: '/admin/licenselist',
        element: <AdminLicenseList/>,
      },
      {
        path: '/facilitator/sessionlist',
        element: <FacilitatorSessionList/>,
      },
      {
        path: '/dashboard',
        element: <Dashboard/>,
      },
      {
        path: '/',
        element: <Dashboard/>,
      },
      {
        path: '/4plants-analytics/:id',
        element: <AnalyticsDashboard/>,
      },
      {
        path: '/noaccess',
        element: <NoAccess/>,
      },
    ]
  },
  {
    path: '/login',
    element: <Login/>,
  }
  
  
]


