import Cookies from 'js-cookie';
import { auth } from '../firebase-config';

export const getUserIdToken = async() => {
  const user = auth.currentUser;
  const accessToken = await user?.getIdToken();
  return accessToken;
};

export const isUserAuthenticated = async() => {
  const user = auth.currentUser;
  const accessToken = await user?.getIdToken();
  if (accessToken) {
    return true;
  }
  return false;
};

export const removeSession = (): void => {
  if ('caches' in window) {
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  Cookies.remove("accessToken", {domain: ".peoplegamez.com", path: "/"});
};
