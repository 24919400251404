import {
  BACKGROUND_ACTION,
  COMMON_ACTION,
  COMMON_ACTION_FAILURE,
  COMMON_ACTION_SUCCESS,
  GAME_LIST,
  GAME_LIST_FAILURE,
  GAME_LIST_SUCCESS,
  LICENSE_LIST,
  LICENSE_LIST_FAILURE,
  LICENSE_LIST_SUCCESS,
  SESSION_LIST,
  SESSION_LIST_FAILURE,
  SESSION_LIST_SUCCESS,
  SET_AUTH_DATA,
  TOGGLE_SESSION_PROMPT,
  TOGGLE_SNACKBAR,
} from '../../constants/actionTypes';

interface Snackbar {
  isOpen: boolean;
  message: string;
  severity: string;
}
type CommonAction = { type: string; payload: any | string };
type State = {
  auth: any;
  games: Array<any>;
  licenses: Array<any>;
  sessions: Array<any>;
  loading: boolean;
  listLoading: boolean;
  sessionPrompt: boolean;
  snackbar: Snackbar;
};

const INIT_STATE = {
  auth: {},
  games: [],
  licenses: [],
  sessions: [],
  loading: false,
  listLoading: false,
  sessionPrompt: false,
  snackbar: {
    isOpen: false,
    message: '',
    severity: 'success',
  },
};

const Common = (state: State = INIT_STATE, action: CommonAction) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return { ...state, auth: action.payload };
    case GAME_LIST:
      return { ...state, loading: true };
    case GAME_LIST_SUCCESS:
      return { ...state, games: action.payload, loading: false };
    case GAME_LIST_FAILURE:
      return { ...state, games: [], loading: false };
    case LICENSE_LIST:
      return { ...state, listLoading: true };
    case LICENSE_LIST_SUCCESS:
      return { ...state, licenses: action.payload, listLoading: false };
    case LICENSE_LIST_FAILURE:
      return { ...state, licenses: [], listLoading: false };
    case SESSION_LIST:
      return { ...state, listLoading: true };
    case SESSION_LIST_SUCCESS:
      return { ...state, sessions: action.payload, listLoading: false };
    case SESSION_LIST_FAILURE:
      return { ...state, sessions: [], listLoading: false };
    case COMMON_ACTION:
      return { ...state, loading: true };
    case COMMON_ACTION_SUCCESS:
      return { ...state, loading: false };
    case COMMON_ACTION_FAILURE:
      return { ...state, loading: false };
    case BACKGROUND_ACTION:
      return { ...state };
    case TOGGLE_SESSION_PROMPT:
      return { ...state, sessionPrompt: action.payload };
    case TOGGLE_SNACKBAR:
      return { ...state, snackbar: action.payload };
    default:
      return { ...state };
  }
};
export default Common;
