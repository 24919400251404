// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAligZbwi1je6AuTRE7di-2TcWwRrSGkwE',
  authDomain: 'sile-afd70.firebaseapp.com',
  databaseURL:
    'https://sile-afd70-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'sile-afd70',
  storageBucket: 'sile-afd70.appspot.com',
  messagingSenderId: '590254401083',
  appId: '1:590254401083:web:a3a42227dcfbe7316f9e1e',
  measurementId: 'G-X6MR3LHKNK',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
// const provider = new GoogleAuthProvider();
export const auth = getAuth(firebaseApp);

// export const signInWithGoogle = () => {
//   signInWithPopup(auth, provider)
//     .then((result) => {
//       return result;
//     })
//     .catch((error) => {
//       return error;
//     });
// };
