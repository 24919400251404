// @ts-nocheck

import { Box, Popover, TextField, Typography, Button } from "@mui/material"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApi } from "../../utils/callApi";
import { REST_URL } from "../../constants/constants";
import { getAuthData } from "../../redux/root_action";




const UpdateProfilePopover = ({anchorElUpdateProf,setAnchorElUpdateProf})=>{
  const open = Boolean(anchorElUpdateProf);
  const id = open ? 'updateProf-popover' : undefined;
  const auth = useSelector((store: any) => store.Common.auth) || {};

  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const dispatch = useDispatch();

  
  const handleChangeName = (event) => {
    setName(event.target.value);
  };


  const handleSubmit = async(event) => {
    event.preventDefault();

    if(name.length ==0 || name.length >50){
      setErrorMessage('Invalid name');
    }

    else {
      setErrorMessage(null);
      const url = `${REST_URL}/user/update-name`;
      try{
        const updatedData = await callApi({url,options: {
          method: 'PUT',
          body:{
            name
          }
        }});
        dispatch(
          getAuthData({
            data: {
              url: `${REST_URL}/user/search`,
              options: {
                method: "POST",
                body: {
                  email: auth?.email,
                },
              },
            },
            successCallback: () => {
              setSuccessMessage('Profile update successful!');
              setTimeout(()=>{
                setAnchorElUpdateProf(null);
              },2000)
            },
            errorCallback: (error: any) => {
              setErrorMessage('An error ocurred');
              console.log(error)
              setSuccessMessage(null);
            },
          })
        );
        
      }
      catch(error){
        setErrorMessage('An error ocurred');
        setSuccessMessage(null);
      }

      
    }
  };

  const handleClose = () => {
    setAnchorElUpdateProf(null);
  };

  useEffect(()=>{
    setName(auth?.name || '');
    setErrorMessage(false);
    setSuccessMessage(false);
  },[anchorElUpdateProf])
    return(
      <Popover
      id={id}
      open={open}
      anchorEl={anchorElUpdateProf}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: window.innerHeight/2, left: window.innerWidth/2 }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Box sx={{p:2}}>
          <Typography sx={{
            fontWeight:'bold'
          }} >Update Profile</Typography>
          <Box sx={{
            width:'400px',
            p:2,
          }}>
           <form onSubmit={handleSubmit}>
              

              <TextField
                type="name"
                label="Name"
                variant="outlined"
                value={name}
                onChange={handleChangeName}
                fullWidth
                margin="normal"
              />

              <TextField
                type="email"
                label="Email"
                variant="outlined"
                value={auth?.email}
                disabled
                fullWidth
                margin="normal"
              />

              {!(errorMessage == null) && <Button sx={{borderRadius:'50px', marginTop:'10px'}} type="submit" variant="contained" color="primary">
                Submit
              </Button>}
              {errorMessage && (
                <Box sx={{color:'red', marginTop:'10px'}}>{errorMessage}</Box>
              )}
              {successMessage && (
                <Box sx={{color:'green', marginTop:'10px'}}>{successMessage}</Box>
              )}
            </form>
          </Box>
      </Box>
    </Popover>
    )
}

export default UpdateProfilePopover;




