import { useTheme } from '@emotion/react';
import { Logout } from '@mui/icons-material';
import HttpsIcon from '@mui/icons-material/Https';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  AppBar,
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { logoutUser } from '../../redux/root_action';
import ChangePasswordPopover from '../popover/ChangePassword';
import UpdateProfilePopover from '../popover/UpdateProfile';

interface Props {}

const Topbar: FC<Props> = (props) => {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useState
  const auth = useSelector((store: any) => store.Common.auth) || {};
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElChngPass, setAnchorElChngPass] = useState<null | HTMLElement>(null);
  const [anchorElUpdateProf, setAnchorElUpdateProf] = useState<null | HTMLElement>(null);
  const settings = [
    { label: 'Update Profile', icon: AccountCircleIcon, id: 'updateProfile' },
    { label: 'Change Password', icon: HttpsIcon, id: 'changePassword' },
    { label: 'Logout', icon: Logout, id: 'logout' },

  ];

  const handleCloseUserMenu = (event:any,settings: any) => {
    if (settings.id === 'logout') {
      dispatch(
        logoutUser({
          successCallback: () => {
            navigate('/login');
          },
        })
      );
    }
    else if (settings.id === 'changePassword') {
      setAnchorElChngPass(event.currentTarget)
    }
    else if (settings.id === 'updateProfile') {
      setAnchorElUpdateProf(event.currentTarget)
    }
    setAnchorElUser(null);
  };


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <AppBar
      position='static'
      sx={{ height: '64px', backgroundColor: theme.palette.common.white }}
    >
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant='h6'
          color='primary'
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          People Gamez..
        </Typography>
        <Tooltip title={'Profile'}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <Avatar
                alt={(auth?.name || 'User')[0].toUpperCase()}
                sx={{
                  bgcolor: theme.palette.primary.main,
                  width: 32,
                  height: 32,
                }}
                src="/static/images/avatar/2.jpg"
              />
              <span style={{fontSize: '10px', color: 'black'}}>{auth?.name || 'User'}</span>
            </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((item: any) => (
            <MenuItem key={item?.id} onClick={(e) => handleCloseUserMenu(e,item)}>
              <ListItemIcon>
                <item.icon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography color='primary' textAlign='center'>
                {item.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
      <ChangePasswordPopover anchorElChngPass={anchorElChngPass} setAnchorElChngPass={setAnchorElChngPass}/>
      <UpdateProfilePopover anchorElUpdateProf={anchorElUpdateProf} setAnchorElUpdateProf={setAnchorElUpdateProf}/>
    </AppBar>
  );
};

export default Topbar;
