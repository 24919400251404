import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FC, Suspense, useEffect, useState } from 'react';
import {  useDispatch } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import './assets/css/index.css';
import Snackbar from './components/snackbar/Snackbar';
import AuthLayout from './pages/layout/AuthLayout';
import { routes } from './routes/routes';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase-config';
import Cookies from 'js-cookie';
import { getAuthData, resetStore, toggleSnackbar } from './redux/root_action';
import { REST_URL } from './constants/constants';
import { getErrorMessage } from './utils/helper';
import { setUserAccessToken } from './utils/setUserAccesToken';
import { useGetRole } from './utils/hooks';

interface Props {}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: { main: '#E4B909', dark: '#524217' },
    secondary: { main: '#F9F2D8' },
    common: { black: '#000', white: '#fff' }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 16, // default font size
    h1: {
      fontSize: '2.5rem', // custom font size for h1
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '18px',
    },
    h4: {
      fontSize: '17px',
    },
  },
});

const theme1 = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1520,
    },
  },
  palette: {
    primary: { main: '#E4B909', dark: '#524217' },
    secondary: { main: '#F9F2D8' },
    common: { black: '#000', white: '#fff' }
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 16, // default font size
    h1: {
      fontSize: '2.5rem', // custom font size for h1
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '18px',
    },
    h4: {
      fontSize: '17px',
    },
  },
});

const App: FC<Props> = (props: Props) => {
const route = useRoutes(routes);
const location = useLocation();
const dispatch = useDispatch();
const role = useGetRole();

const navigate = useNavigate();
const [loading,setLoading] = useState(false);
const [error, setError] = useState("");
const [userLoaded,setUserLoaded] = useState(false);

const handleSignInSuccess = async(user: any) => {
  setLoading(true);
  try{
    await setUserAccessToken();
    dispatch(
      getAuthData({
        data: {
          url: `${REST_URL}/user/search`,
          options: {
            method: "POST",
            body: {
              email: user.email,
            },
          },
        },
        successCallback: () => {
          setLoading(false);
          setError("");
          dispatch(
            toggleSnackbar({
              isOpen: true,
              message: `Login Succesfull`,
              severity: "success",
            })
          );
          if(location.pathname == '/login' || location.pathname == '/'){
            navigate("/dashboard");
          }
        },
        errorCallback: (error: any) => {
          setLoading(false);
          setError(getErrorMessage(error));
          Cookies.remove("accessToken", {domain: ".peoplegamez.com", path: "/"});
        },
      })
    );
  }
  catch(err){
    setLoading(false);
    if(role == null){
      navigate('/noaccess')
    }
  }
  
  
};
const handleSignInFailure = (error: string) => {
  setLoading(false);
  dispatch(resetStore());
  setError(error);
  Cookies.remove("accessToken", {domain: ".peoplegamez.com", path: "/"});
};

useEffect(()=>{
  onAuthStateChanged(auth, async(user) => {
    if (user) {
      try {
        await handleSignInSuccess(user);
        setUserLoaded(true)
      } catch (error: any) {
        handleSignInFailure(getErrorMessage(error));
      }
      
    } else {
      setUserLoaded(true)
      handleSignInFailure('Signed out');
      navigate('/login');
      Cookies.remove("accessToken", {domain: ".peoplegamez.com", path: "/"});
      Cookies.remove("accessToken");
    }
  });
},[])

  return (
    <ThemeProvider theme={window.location.pathname.split('/').includes('4plants-analytics') ? theme1 : theme}>
        <Suspense fallback={<div></div>}>
           {userLoaded && route}
        </Suspense>
          <Snackbar />
    </ThemeProvider>
  );
};

export default App;
