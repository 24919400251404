import { isUserAuthenticated } from '../utils/auth';
import { Outlet, useNavigate,useLocation } from 'react-router-dom'



export const PrivateRoute = () => {
  const location = useLocation();

  return <Outlet/>;
};
