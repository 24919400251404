export const getErrorMessage = (error: any) => {
  console.log('getErrorMessage', error);
  if (error && error.message) {
    return error.message;
  }
  return 'something went wrong';
};

export const filterRecords = (
  value: string,
  list: Array<any>,
  columns: Array<any>
) => {
  if (value) {
    const rows: any = [];
    const len = list.length;
    const columnLen = columns.length;
    let i,
      j,
      k = 0;
    for (i = 0; i < len; i++) {
      let isFound = false;
      const row = list[i];
      for (j = 0; j < columnLen; j++) {
        const column = columns[j];
        const path = column?.path || '';
        const filterPath = column?.filterPath || '';
        if (column.filterType === 'array' && path && filterPath) {
          const child = row[path] || [];
          const childLen = child.length;
          for (k = 0; k < childLen; k++) {
            const data = child[k][filterPath] || '';
            if (
              // data.toString().toLowerCase().search(value.toLowerCase()) !== -1
              data.toString().toLowerCase().startsWith(value.toLowerCase())
            ) {
              isFound = true;
              break;
            }
          }
          if (isFound) {
            break;
          }
        } else if (path) {
          const data = row[path] || '';
          if (
            // data.toString().toLowerCase().search(value.toLowerCase()) !== -1
            data.toString().toLowerCase().startsWith(value.toLowerCase())
          ) {
            isFound = true;
            break;
          }
        }
      }
      if (isFound) {
        rows.push(row);
      }
    }
    return rows;
  } else {
    return list;
  }
};
