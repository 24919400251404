import Cookies from "js-cookie";
import { callApi } from "../4plants-analytics/utils/callApi"
import { REST_URL } from "../constants/constants"


export const setUserAccessToken = async()=>{
    const data = {
        url: `${REST_URL}/user-custom-token/create`,
        options: {
            method: 'POST',
            body: {
                
            },
        },
    }
    const response = await callApi(data);
    const userCustomToken = encodeURIComponent(response?.userCustomToken)
    Cookies.set("accessToken", userCustomToken, {
        domain: ".peoplegamez.com",
        path: "/",
      });
    // Cookies.set("accessToken", userCustomToken);

      return userCustomToken

}