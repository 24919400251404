import {
  BACKGROUND_ACTION,
  COMMON_ACTION,
  COMMON_ACTION_FAILURE,
  COMMON_ACTION_SUCCESS,
  GAME_LIST,
  GAME_LIST_FAILURE,
  GAME_LIST_SUCCESS,
  GET_AUTH_DATA,
  LICENSE_LIST,
  LICENSE_LIST_FAILURE,
  LICENSE_LIST_SUCCESS,
  LOGOUT_USER,
  RESET_STORE,
  SESSION_LIST,
  SESSION_LIST_FAILURE,
  SESSION_LIST_SUCCESS,
  SET_AUTH_DATA,
  TOGGLE_SESSION_PROMPT,
  TOGGLE_SNACKBAR,
} from '../../constants/actionTypes';
type CommonAction = { type: string; payload: {} | string };

export const resetStore = (): CommonAction => ({
  type: RESET_STORE,
  payload: {},
});
export const logoutUser = (data: any): CommonAction => ({
  type: LOGOUT_USER,
  payload: data,
});
export const getAuthData = (data: any): CommonAction => ({
  type: GET_AUTH_DATA,
  payload: data,
});
export const setAuthData = (data: any): CommonAction => ({
  type: SET_AUTH_DATA,
  payload: data,
});
export const toggleSessionPrompt = (data: boolean): CommonAction => ({
  type: TOGGLE_SESSION_PROMPT,
  payload: data,
});
export const toggleSnackbar = (data: any): CommonAction => ({
  type: TOGGLE_SNACKBAR,
  payload: data,
});
export const gameList = (data: any): CommonAction => ({
  type: GAME_LIST,
  payload: data,
});
export const gameListSuccess = (data: any): CommonAction => ({
  type: GAME_LIST_SUCCESS,
  payload: data,
});
export const gameListFailure = (): CommonAction => ({
  type: GAME_LIST_FAILURE,
  payload: {},
});

export const licenseList = (data: any): CommonAction => ({
  type: LICENSE_LIST,
  payload: data,
});
export const licenseListSuccess = (data: any): CommonAction => ({
  type: LICENSE_LIST_SUCCESS,
  payload: data,
});
export const licenseListFailure = (): CommonAction => ({
  type: LICENSE_LIST_FAILURE,
  payload: {},
});

export const sessionList = (data: any): CommonAction => ({
  type: SESSION_LIST,
  payload: data,
});
export const sessionListSuccess = (data: any): CommonAction => ({
  type: SESSION_LIST_SUCCESS,
  payload: data,
});
export const sessionListFailure = (): CommonAction => ({
  type: SESSION_LIST_FAILURE,
  payload: {},
});

export const commonAction = (data: any): CommonAction => ({
  type: COMMON_ACTION,
  payload: data,
});
export const commonActionSuccess = (data: any): CommonAction => ({
  type: COMMON_ACTION_SUCCESS,
  payload: data,
});
export const commonActionFailure = (): CommonAction => ({
  type: COMMON_ACTION_FAILURE,
  payload: {},
});

export const backgroundAction = (data: any): CommonAction => ({
  type: BACKGROUND_ACTION,
  payload: data,
});
