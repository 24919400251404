import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { RESET_STORE } from '../constants/actionTypes';
import Common from './common/reducer';

const appReducers = combineReducers({
  Common,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === REHYDRATE) {
    return { ...state, persistedState: action.payload };
  }
  if (action.type === RESET_STORE) {
    state = {};
  }
  return appReducers(state, action);
};
export default rootReducer;
