import React, { FC } from "react";
import Topbar from "../../components/topbar/Topbar";
import { Typography } from "@mui/material";

interface Props {}

const NoAccess: FC<Props>  = (props) => {

    return(
       <>
       <Topbar/>
       <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 64px - 100px)',
          width: '100%',
        }}
      >
       <Typography fontSize={'48px'}>You don't have any access</Typography>

      </div>
       </>
    )
}


export default NoAccess;