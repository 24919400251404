export const RESET_STORE = 'RESET_STORE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_AUTH_DATA = 'GET_AUTH_DATA';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const TOGGLE_SESSION_PROMPT = 'TOGGLE_SESSION_PROMPT';
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const GAME_LIST = 'GAME_LIST';
export const GAME_LIST_SUCCESS = 'GAME_LIST_SUCCESS';
export const GAME_LIST_FAILURE = 'GAME_LIST_FAILURE';
export const LICENSE_LIST = 'LICENSE_LIST';
export const LICENSE_LIST_SUCCESS = 'LICENSE_LIST_SUCCESS';
export const LICENSE_LIST_FAILURE = 'LICENSE_LIST_FAILURE';
export const SESSION_LIST = 'SESSION_LIST';
export const SESSION_LIST_SUCCESS = 'SESSION_LIST_SUCCESS';
export const SESSION_LIST_FAILURE = 'SESSION_LIST_FAILURE';
export const COMMON_ACTION = 'COMMON_ACTION';
export const COMMON_ACTION_SUCCESS = 'COMMON_ACTION_SUCCESS';
export const COMMON_ACTION_FAILURE = 'COMMON_ACTION_FAILURE';
export const BACKGROUND_ACTION = 'BACKGROUND_ACTION';
