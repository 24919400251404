import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  BACKGROUND_ACTION,
  COMMON_ACTION,
  GAME_LIST,
  GET_AUTH_DATA,
  LICENSE_LIST,
  LOGOUT_USER,
  SESSION_LIST,
} from '../../constants/actionTypes';
import { removeSession } from '../../utils/auth';
import { callApi } from '../../utils/callApi';
import { getErrorMessage } from '../../utils/helper';
import {
  commonActionFailure,
  commonActionSuccess,
  gameListFailure,
  gameListSuccess,
  licenseListFailure,
  licenseListSuccess,
  resetStore,
  sessionListFailure,
  sessionListSuccess,
  setAuthData,
  toggleSessionPrompt,
  toggleSnackbar,
} from './action';
import { auth } from '../../firebase-config';

function* getAuthData({ payload }: any) {
  const { data, successCallback, errorCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    yield put(setAuthData(response));
    successCallback?.();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(setAuthData({}));
      errorCallback?.(error);
    }
  }
}

function* getGameList({ payload }: any) {
  const { data, successCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    yield put(gameListSuccess(response));
    successCallback?.();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(
        toggleSnackbar({
          isOpen: true,
          message: getErrorMessage(error),
          severity: 'error',
        })
      );
      yield put(gameListFailure());
    }
  }
}

function* getLicenseList({ payload }: any) {
  const { data, successCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    yield put(licenseListSuccess(response));
    successCallback?.();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(
        toggleSnackbar({
          isOpen: true,
          message: getErrorMessage(error),
          severity: 'error',
        })
      );
      yield put(licenseListFailure());
    }
  }
}

function* getSessionList({ payload }: any) {
  const { data, successCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    yield put(sessionListSuccess(response));
    successCallback?.();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(
        toggleSnackbar({
          isOpen: true,
          message: getErrorMessage(error),
          severity: 'error',
        })
      );
      yield put(sessionListFailure());
    }
  }
}

function* commonAction({ payload }: any) {
  const { data, successmsg, successCallback, errorCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    yield put(commonActionSuccess(response));
    if (successmsg) {
      yield put(
        toggleSnackbar({
          isOpen: true,
          message: successmsg,
          severity: 'success',
        })
      );
    }
    successCallback?.();
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(
        toggleSnackbar({
          isOpen: true,
          message: getErrorMessage(error),
          severity: 'error',
        })
      );
      yield put(commonActionFailure());
      errorCallback?.();
    }
  }
}

function* backgroundAction({ payload }: any) {
  const { data, successCallback, errorCallback } = payload;
  try {
    const response: Promise<any> = yield call(callApi, data);
    successCallback?.(response);
  } catch (error: any) {
    if (error.message === 'Unauthorized') {
      yield put(toggleSessionPrompt(true));
    } else {
      errorCallback?.();
    }
  }
}

function* logout({ payload }: any) {
  const { successCallback } = payload;


  try {
    // @ts-ignore
    const resp = yield call([auth, auth.signOut]);
    yield put(resetStore());
    removeSession();
    // yield put(push('/login'));
    successCallback?.();
  } catch (error) {
    console.log('error', error);
    yield put(
      toggleSnackbar({
        isOpen: true,
        message: getErrorMessage(error),
        severity: 'error',
      })
    );
  }
}

function* commonSaga() {
  yield all([
    takeLatest(GET_AUTH_DATA, getAuthData),
    takeLatest(GAME_LIST, getGameList),
    takeLatest(LICENSE_LIST, getLicenseList),
    takeLatest(SESSION_LIST, getSessionList),
    takeLatest(COMMON_ACTION, commonAction),
    takeLatest(BACKGROUND_ACTION, backgroundAction),
    takeLatest(LOGOUT_USER, logout),
  ]);
}

export default commonSaga;
