// @ts-nocheck

import { Box, Popover, TextField, Typography, Button } from "@mui/material"
import React, { useEffect, useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";




const ChangePasswordPopover = ({anchorElChngPass,setAnchorElChngPass})=>{
  const open = Boolean(anchorElChngPass);
  const id = open ? 'passwordChange-popover' : undefined;

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const isPasswordValid = (password) => {
    // const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  
    return password.length >=6;
  };

 

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    if(!isPasswordValid(newPassword)){
      setErrorMessage('Password must be at least 6 characters long');
    }

    else if (newPassword !== confirmPassword) {
      setErrorMessage('New Password and Confirm Password are not same');
      
    } else {
      setErrorMessage(null);
      const auth = getAuth();
      const user = auth.currentUser;
      updatePassword(user, newPassword).then(() => {
        setSuccessMessage('Password change successful!');
        setTimeout(()=>{
          setAnchorElChngPass(null);
        },2000)
      }).catch((error) => {
        setErrorMessage('Logout and login Again to change the password');
        console.log(error)
        setSuccessMessage(null);
      });
      
    }
  };

  const handleClose = () => {
    setAnchorElChngPass(null);
  };

  useEffect(()=>{
    setNewPassword('');
    setConfirmPassword('');
    setErrorMessage(false);
    setSuccessMessage(false);
  },[anchorElChngPass])
    return(
      <Popover
      id={id}
      open={open}
      anchorEl={anchorElChngPass}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: window.innerHeight/2, left: window.innerWidth/2 }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Box sx={{p:2}}>
          <Typography sx={{
            fontWeight:'bold'
          }} >Change Password</Typography>
          <Box sx={{
            width:'400px',
            p:2,
          }}>
           <form onSubmit={handleSubmit}>
              

              <TextField
                type="password"
                label="New Password"
                variant="outlined"
                value={newPassword}
                onChange={handleNewPasswordChange}
                fullWidth
                margin="normal"
              />

              <TextField
                type="password"
                label="Confirm New Password"
                variant="outlined"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                fullWidth
                margin="normal"
              />

              {!(errorMessage == null) && <Button sx={{borderRadius:'50px', marginTop:'10px'}} type="submit" variant="contained" color="primary">
                Submit
              </Button>}
              {errorMessage && (
                <Box sx={{color:'red', marginTop:'10px'}}>{errorMessage}</Box>
              )}
              {successMessage && (
                <Box sx={{color:'green', marginTop:'10px'}}>{successMessage}</Box>
              )}
            </form>
          </Box>
      </Box>
    </Popover>
    )
}

export default ChangePasswordPopover;




